import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import { Helmet } from "react-helmet";
import Tagline from "../components/sections/tagline";

const Category = ({
  data: {
    allWpPost: posts,
    wpCategory: category,
    wpPage: page,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
  pageContext,
  location,
}) => {
  const banner = {
    name: "blog-banner",
    image: category.blog.background,
    altText: category.blog.background?.altText
      ? category.blog.background.altText
      : "",
    pageContext: pageContext,
    location: location,
  };
  const tagline = {
    name: "blog-tagline",
    text: category.blog.taglineText
      ? category.blog.taglineText
      : "BUILDING TO A HIGHER STANDARD",
  };

  const blogContent = category.blog.content;

  const blogTitle = category.blog.content ? "" : category.name;
  return (
    <>
      <Helmet>
        <title>
          {category.page.metaTitle ? category.page.metaTitle : category.name} -
          {themeSettings.siteMetaTitle}
        </title>
        {category.metaDescription && (
          <meta name="description" content={category.metaDescription} />
        )}
        {category.pageCssOverrides && (
          <style>{category.pageCssOverrides}</style>
        )}
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}
      </Helmet>
      <BlogElem
        title={category.title}
        banner={banner}
        pageContext={pageContext}
        location={location}
        blogContent={blogContent}
        blogTitle={blogTitle}
      >
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      </BlogElem>
      <Tagline data={tagline}></Tagline>
    </>
  );
};

export const pageQuery = graphql`
  query Category(
    $id: String!
    $perPage: Int!
    $skipItems: Int!
    $parentId: String!
  ) {
    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
          page {
            metaDescription
          }
        }
      }
    }
    wpCategory(id: { eq: $id }) {
      name
      blog {
        content
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
        taglineText
      }
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
    }
    wpPage(id: { eq: $parentId }) {
      blog {
        content
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Category;
